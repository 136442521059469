import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';

import PhoneNumberTextField from '../common/phone-number-texfield';
import { AccessStatusTypeEnum, UserDetailsResponse, TransactionLimitSettingValue, UserAccount } from '../../types';
import { DateFormatTypeEnum, formatDinero, formatTimeStamp, getDinero, joinNameParts } from '../../utilities';
import { AppTextField } from '../common/app-textfield';
import StatusButton from '../common/status-button';
import FraudDetails from '../manage-black-listed-emails/fraud-details';
import { Box } from '@material-ui/core';
import AddFunds from '../manage-business-user/add-funds';
import storeContext from '../../contexts/store-context';

export const manageUserEditFormUseStyles = makeStyles((theme) => ({
    form: {
        padding: 16,
        boxShadow: theme.shadows[4],
        borderRadius: 8,
        margin: '24px auto',
        '& $innerForm': {
            marginTop: 36
        },
        '& $heading': {
            textAlign: 'left',
            fontSize: 20,
            fontWeight: 600,
            margin: '12px 0'
        },
        '& $footer': {
            width: '100%',
            display: 'flex',
            padding: '16px 0',
            justifyContent: 'center',
            [theme.breakpoints.up('sm')]: {
                justifyContent: 'flex-end'
            },
            '& button': {
                margin: '0 8px',
                [theme.breakpoints.up('sm')]: {
                    margin: '0 0 0 12px'
                }
            }
        }
    },
    textField: {
        marginBottom: 24,
        width: '100%',
        '& textarea': {
            minHeight: 76
        }
    },
    mainContainer: {
        [theme.breakpoints.up('lg')]: {
            display: 'flex'
        },
        '& $inner': {
            [theme.breakpoints.up('lg')]: {
                display: 'flex'
            }
        }
    },
    approveButton: {
        background: theme.palette.success.main,
        color: theme.palette.common.white,
        margin: '0 8px',
        '&:hover': {
            background: theme.palette.success.light
        }
    },
    rejectButton: {
        background: theme.palette.error.main,
        color: theme.palette.common.white,
        margin: '0 8px',
        '&:hover': {
            background: theme.palette.error.light
        }
    },
    details: {
        textAlign: 'left'
    },
    staticFields: {
        display: 'flex',
        textAlign: 'left',
        '& > span': {
            '&:first-child': {
                fontWeight: 900,
                marginRight: 12
            }
        },
        '& > input': {
            padding: 0,
            textAlign: 'left',
            fontSize: '1rem',
            fontWeight: 400,
            lineHeight: '1.5',
            letterSpacing: '0.00938em'
        }
    },
    address: {
        borderRadius: 8,
        padding: 8,
        margin: '8px 0',
        boxShadow: theme.shadows[2],
        border: `1px solid ${theme.palette.primary.light}`
    },
    primary: {
        background: '#81c7844d',
        border: `1px solid ${theme.palette.success.light}`
    },
    phoneNumber: {
        fontSize: 16,
        fontWeight: 900,
        color: theme.palette.text.primary,
        '& label': {
            display: 'none'
        },
        '& input': {
            fontWeight: 900,
            padding: 8,
            textAlign: 'center',
            color: theme.palette.text.primary,
            '&.Mui-disabled': {
                textAlign: 'left',
                paddingTop: 4,
                paddingLeft: 0,
                fontWeight: 400
            }
        },
        '& div *': {
            background: 'transparent !important',
            backgroundColor: 'transparent !important'
        },
        '& *:after, *:before': {
            display: 'none'
        }
    },
    innerForm: {},
    inner: {},
    heading: {},
    footer: {}
}));

export const FormattedPhoneNumber = (props: { phone?: string | null; country?: string | null }) => {
    const classes = manageUserEditFormUseStyles();
    const { phone, country } = props;

    if (!phone) {
        return null;
    }

    if (phone && !country) {
        return <div className={classes.phoneNumber}>{phone}</div>;
    }

    return (
        <div className={classes.phoneNumber}>
            <PhoneNumberTextField
                hideCountrySelector={true}
                country={country || null}
                phone={phone}
                isPhoneInvalid={false}
                onChange={() => {
                    return;
                }}
                InputProps={{ readOnly: true, disabled: true }}
                size="medium"
            />
        </div>
    );
};

type Props = {
    user: Partial<UserDetailsResponse>;
    transactionLimitSetting?: TransactionLimitSettingValue | null;
    hideRevokingReasonFormField?: boolean;
    onCancel?: () => void;
    onUpdate?: (user: Array<Partial<UserDetailsResponse>>) => Promise<void>;
};

type State = {
    isApproving: boolean;
    isRejecting: boolean;
    user: Partial<UserDetailsResponse> | null;
    transactionLimitSetting?: TransactionLimitSettingValue | null;
};

const DEFAULT_STATE: State = {
    isApproving: false,
    isRejecting: false,
    user: null,
    transactionLimitSetting: null
};

const REASON_MAX_LENGTH = 500;
const NULL_TEXT = 'Information not available';

export const ManageUserEditForm = (props: Props) => {
    const classes = manageUserEditFormUseStyles();
    const { manageUserAction } = storeContext();
    const { hideRevokingReasonFormField, onCancel, onUpdate } = props;

    const [state, setState] = useState<State>({
        ...DEFAULT_STATE,
        user: props.user,
        transactionLimitSetting: props.transactionLimitSetting
    });
    const [balanceData, setBalanceData] = useState({
        balance: 0,
        loading: false
    });

    useEffect(() => {
        setState((prevState) => ({
            ...prevState,
            user: props.user,
            transactionLimitSetting: props.transactionLimitSetting
        }));
    }, [props.user, props.transactionLimitSetting]);

    useEffect(() => {
        if (props.user?.userId) {
            fetchFundBalance(props.user.userId);
        }
    }, [props.user?.userId]);

    const businessDosReasonLength = (state.user?.accessUpdateReason || '').length;

    const toBeApproved =
        state.user?.accessStatus === 'ON_WATCH' ||
        state.user?.accessStatus === 'APPROVAL_PENDING' ||
        state.user?.accessStatus === 'REVOKED';
    const toBeRejected =
        state.user?.accessStatus === 'ON_WATCH' ||
        state.user?.accessStatus === 'APPROVAL_PENDING' ||
        state.user?.accessStatus === 'APPROVED';

    const updateUser = async (accessStatus: typeof AccessStatusTypeEnum[number]) => {
        if (!state.user || businessDosReasonLength > REASON_MAX_LENGTH || !onUpdate || !onCancel) {
            return;
        }

        setState((prevState) => ({
            ...prevState,
            isApproving: !!(accessStatus === 'APPROVED'),
            isRejecting: !!(accessStatus === 'REVOKED')
        }));
        const updates: Partial<UserAccount> = {
            ...state.user,
            accessStatus,
            accessUpdateReason: state.user.accessUpdateReason
        };

        if (accessStatus === 'APPROVED') {
            updates.fraudScore = null;
        }

        await onUpdate([updates]);
        setState((prevState) => ({ ...prevState, isApproving: false, isRejecting: false }));
        onCancel();
    };

    const fetchFundBalance = async (userId: number) => {
        setBalanceData((prevState) => ({
            ...prevState,
            loading: true,
            balance: 0
        }));
        const balance = await manageUserAction()?.getWalletBalanceByUserId(userId);
        setBalanceData((prevState) => ({
            ...prevState,
            balance: balance || 0,
            loading: false
        }));
    };

    const name = joinNameParts(state.user);

    return (
        <Container className={classes.mainContainer}>
            <Grid container={true} className={classes.inner}>
                <Grid item={true} xs={12} className={classes.form}>
                    <Grid container={true} alignItems="center" justifyContent="space-between">
                        <Typography className={classes.heading}>User Details</Typography>

                        <StatusButton
                            options={[
                                {
                                    type: 'APPROVAL_PENDING',
                                    text: 'Pending',
                                    color: 'warning'
                                },
                                {
                                    type: 'REVOKED',
                                    text: 'Access Revoked',
                                    color: 'danger'
                                },
                                {
                                    type: 'ON_WATCH',
                                    text: 'ON WATCH',
                                    color: 'warning'
                                }
                            ]}
                            type={state.user?.accessStatus}
                        />
                    </Grid>

                    <Grid item={true} xs={12} className={classes.details}>
                        <Grid item={true} xs={12} className={classes.staticFields}>
                            <Typography color="textSecondary" component="span">
                                User Id:
                            </Typography>
                            <Typography component="span">#{state.user?.userId}</Typography>
                        </Grid>

                        <Grid item={true} xs={12} className={classes.staticFields}>
                            <Typography color="textSecondary" component="span">
                                Clarity Custom User Id:
                            </Typography>
                            <Typography component="span">{state.user?.clarityUserId || 'NA'}</Typography>
                        </Grid>

                        <Grid item={true} xs={12} className={classes.staticFields}>
                            <Typography color="textSecondary" component="span">
                                Name:
                            </Typography>
                            <Typography component="span">{name || NULL_TEXT}</Typography>
                        </Grid>

                        <Grid item={true} xs={12} className={classes.staticFields}>
                            <Typography color="textSecondary" component="span">
                                Email:
                            </Typography>
                            <Typography component="span">{state.user?.email || NULL_TEXT}</Typography>
                        </Grid>

                        <Grid item={true} xs={12} className={classes.staticFields}>
                            <Typography color="textSecondary" component="span">
                                Previous Emails:
                            </Typography>
                            <Typography component="span">
                                {state?.user?.previousEmails ? state?.user?.previousEmails.toString() : '-'}
                            </Typography>
                        </Grid>

                        <Grid item={true} xs={12} className={classes.staticFields}>
                            <Typography color="textSecondary" component="span">
                                Provider:
                            </Typography>
                            <Typography component="span">{state.user?.provider || NULL_TEXT}</Typography>
                        </Grid>

                        <Grid item={true} xs={12} className={classes.staticFields}>
                            <Typography color="textSecondary" component="span">
                                Last Visit:
                            </Typography>
                            <Typography component="span">
                                {formatTimeStamp(state.user?.lastVisit, DateFormatTypeEnum.MMMM_D_YYYY_h_mm_a)}
                            </Typography>
                        </Grid>

                        {state.transactionLimitSetting && (
                            <Grid item={true} xs={12} className={classes.staticFields}>
                                <Typography color="textSecondary" component="span">
                                    Transaction Limit:
                                </Typography>
                                <Typography component="span">
                                    <b>{state.transactionLimitSetting.key}</b> - $
                                    {state.transactionLimitSetting.transactionCostLimit} /{' '}
                                    {state.transactionLimitSetting.transactionCountLimit} (Transaction Cost /
                                    Transaction Count)
                                </Typography>
                            </Grid>
                        )}

                        <Grid item={true} xs={12} className={classes.staticFields}>
                            <Typography color="textSecondary" component="span">
                                Mobile number:
                            </Typography>
                            <FormattedPhoneNumber phone={state?.user?.phone} country={state?.user?.country} />
                        </Grid>

                        <Grid item={true} xs={12} className={classes.staticFields}>
                            <Typography color="textSecondary" component="span">
                                Wallet Funds:
                            </Typography>
                            {balanceData.loading && <CircularProgress />}
                            {!balanceData.loading && (
                                <Typography component="span">{formatDinero(getDinero(balanceData.balance))}</Typography>
                            )}
                        </Grid>

                        {state.user?.userId && (
                            <Grid item xs={12}>
                                <Box textAlign="left">
                                    <AddFunds
                                        userId={state.user.userId}
                                        inputTitle="Add Wallet Funds"
                                        fetchFundBalance={() => fetchFundBalance(props.user.userId!)}
                                        creditFundsAPI={(amount) =>
                                            manageUserAction()?.creditWalletFundsByUserId(props.user.userId!, amount)
                                        }
                                    />
                                </Box>
                            </Grid>
                        )}
                    </Grid>

                    {(state?.user?.fraudDataJson || state?.user?.emailDataJson) && (
                        <Grid item={true} xs={12} className={classes.details}>
                            <FraudDetails
                                fraudDataJson={state?.user?.fraudDataJson}
                                ipAddress={state?.user?.ipAddress}
                                emailDataJson={state?.user?.emailDataJson}
                                email={state?.user?.email}
                            />
                        </Grid>
                    )}

                    {!hideRevokingReasonFormField && (
                        <Grid item={true} xs={12} className={classes.innerForm}>
                            <AppTextField
                                className={classes.textField}
                                variant="outlined"
                                size="small"
                                label="Account Access Update reason"
                                placeholder="Reason for updating user's account access..."
                                multiline={true}
                                fullWidth={true}
                                maxLength={REASON_MAX_LENGTH}
                                value={state.user?.accessUpdateReason || ''}
                                helperText={
                                    (state.user?.accessUpdateReason &&
                                        businessDosReasonLength <= REASON_MAX_LENGTH &&
                                        `${REASON_MAX_LENGTH - businessDosReasonLength} characters remaining`) ||
                                    `maximum 500 characters allowed`
                                }
                                error={!!(businessDosReasonLength > REASON_MAX_LENGTH)}
                                onChange={(e) =>
                                    setState({
                                        ...state,
                                        user: { ...state.user, accessUpdateReason: e.target.value }
                                    })
                                }
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                    )}

                    <Grid item={true} xs={12} className={classes.footer}>
                        {onCancel && (
                            <Button
                                variant="contained"
                                size="small"
                                onClick={(e) => {
                                    onCancel();
                                    setState(DEFAULT_STATE);
                                }}
                                disabled={state.isApproving || state.isRejecting}
                            >
                                Cancel
                            </Button>
                        )}

                        {onUpdate && (
                            <>
                                {toBeApproved && (
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="inherit"
                                        size="small"
                                        disabled={state.isApproving || state.isRejecting}
                                        className={classes.approveButton}
                                        onClick={(e) => updateUser('APPROVED')}
                                    >
                                        {state.isApproving && <CircularProgress className="button-loader" />}
                                        <ThumbUpIcon />
                                        &nbsp;Approve
                                    </Button>
                                )}

                                {toBeRejected && (
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="inherit"
                                        size="small"
                                        disabled={state.isApproving || state.isRejecting}
                                        className={classes.rejectButton}
                                        onClick={(e) => updateUser('REVOKED')}
                                    >
                                        {state.isRejecting && <CircularProgress className="button-loader" />}
                                        <ThumbDownIcon />
                                        &nbsp;Revoke
                                    </Button>
                                )}
                            </>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
};

export default ManageUserEditForm;
