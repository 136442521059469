import { OrderUserTypeEnum } from '../components/dashboard/order-history';
import { PlatformTypeEnum } from './blackListedEmail';
import { EGifterOrderType, EGifterOrderTypeEnum } from './eGifter';
import { BarcodeCode, CardProviderType, GiftCardProduct } from './giftCard';
import { PaymentAttributes } from './payment';
import { PaymentMethod } from './paymentMethod';
import { PromoCode } from './promoCode';
import { RecipientDeliveryScheduledAtType } from './recipient';
import { UserBillingAddress } from './userBillingAddress';

export const OrderStatusEnum = [
    'COMPLETE',
    'PENDING',
    'FAIL',
    'SCHEDULED',
    'CANCELED',
    'ON_HOLD'
] as const;

export const B2COrderFailureReasonTypeEnum = [
    'GIFT_CARD_ORDER_FAILURE',
    'INTERNAL_FAILURE',
    'FRAUD_IP_VALIDATION',
    'FRAUD_BIN_IP_VALIDATION',
    'PAYMENT_FAILURE_GATEWAY_ERROR',
    'PAYMENT_FAILURE_THREE_DS_VALIDATION_FAILED',
    'PAYMENT_FAILURE_THREE_DS_CHALLENGE_RESPONSE_MISSING',
    'PAYMENT_FAILURE_THREE_DS_CHALLENGE_FAILED',
    'ADMIN_REVOKED',
] as const;

export const B2BOrderFailureReasonTypeEnum = [
    'INTERNAL_FAILURE',
    'FRAUD_IP_VALIDATION',
    'FRAUD_BIN_IP_VALIDATION',
    'PAYMENT_FAILURE_GATEWAY_ERROR',
    'PAYMENT_FAILURE_THREE_DS_VALIDATION_FAILED',
    'PAYMENT_FAILURE_THREE_DS_CHALLENGE_RESPONSE_MISSING',
    'PAYMENT_FAILURE_THREE_DS_CHALLENGE_FAILED',
    'GIFT_CARD_ORDER_FAILURE',
    'GIFT_CARD_REDEMPTION_PIN_ERROR',
    'GIFT_CARD_REDEMPTION_INVALID_CARD_ERROR',
    'GIFT_CARD_REDEMPTION_BALANCE_ERROR',
    'GIFT_CARD_REDEMPTION_PENDING_DELIVERY_ERROR',
    'WALLET_BALANCE_ERROR',
    'CARD_REAUTHENTICATION_REQUIRED',
    'ADMIN_REVOKED',
] as const;

export type OrderFailureReason = typeof B2COrderFailureReasonTypeEnum[number] | typeof B2BOrderFailureReasonTypeEnum[number];

export const OrderItemRecipientStatusEnum = ['SUCCESS', 'PENDING', 'FAIL', 'SCHEDULED', 'CANCELED'] as const; // this is the status for email sent via NODE_MAILER

export const ReferralCashbackEnum = ['REFER_A_FRIEND'] as const;

export const B2BOrderTypeEnum = [
    'GIFT_CARD_PURCHASE',
    'GIFT_CARD_PURCHASE_WITH_API',
    'SUBSCRIPTION',
    'CARDMOOLA_FUNDS'
] as const;

export type CustomerType = 'B2C' | 'B2B';

export type OrderPayloadGiftCardProduct = Omit<Partial<GiftCardProduct>, 'price' | 'quantity' | 'recipients'> & {
    price: number;
    convertedPrice: number;
    quantity: number;
    productId: string;
    photo?: string;
    logo?: string;
    itemId?: number;
    baseEGifterFee: number;
    eGifterDiscount: number;
    recipientListIds?: Array<number>;
    deliveryType: typeof OrderItemRecipientDeliveryTypeEnum[number];
    provider: CardProviderType;
    encodedBusinessName?: string;
};

export interface OrderAttributes {
    orderId?: number | null;
    paymentId?: number | null;
    userId?: number;
    billingAddressId?: number | null;
    promoCodeId?: number | null;
    status?: typeof OrderStatusEnum[number];
    total?: number | null;
    cashback?: number | null;
    walletFundsUsed?: number | null;
    eGifterOrderId?: string | null;
    eGifterPoNumber?: string | null;
    eGifterCost?: number | null;
    eGifterFee?: number | null;
    paymentGatewayFee?: number | null;
    /**
     * @default 'Links'
     */
    orderType: EGifterOrderType;
    eGifterFailStatusCode?: number | null;
    referralCashbackAmount?: number | null;
    referralCashbackReason?: typeof ReferralCashbackEnum[number] | null;
    platformType?: typeof PlatformTypeEnum[number] | null;
    payoutAmount?: number | null;
    orderFailureReason?: OrderFailureReason | null;
    /**
     * Store the request payload sent to fraud service
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fraudRequestPayload?: Record<string, any> | null;
    /**
     * Used to store the response received from the fraud service
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fraudResponse?: Record<string, any> | null;
    createdAt?: string | null;
    updatedAt?: string | null;
}

type OrderRedemptionDetail = {
    redemptionId: number;
    giftCardName: string;
    giftCardNumber: string;
    amountUsed: number;
    baseAmountUsed: number;
    currencyCode: string;
};

export interface OrderSummary {
    status?: typeof OrderStatusEnum[number];
    total?: number;
    baseTotal?: number;
    convertedTotal: number;
    convertedCurrency: string;
    cultureCode: string;
    exchangeRate: string;
    eGifterFee: number;
    paymentGatewayFee: number;
    cashback?: number;
    cardmoolaFundsUsed?: number;
    cardmoolaFundsAdded?: number;
    walletFundsUsed?: number;
    orderId: number;
    userId?: number;
    createdAt?: string | null;
    updatedAt?: string | null;
    promoCode?: Pick<PromoCode, 'promoCodeId' | 'key' | 'type' | 'value' | 'maxAmount' | 'encodedBusinessName'> | null;
    redemptionDetails?: OrderRedemptionDetail | null;
    items: Array<
        OrderPayloadGiftCardProduct & {
            recipients: OrderItemRecipient[];
            basePrice?: number;
            convertedPrice?: number;
        }
    >;
    payment: {
        paymentId: PaymentAttributes['paymentId'];
        status: PaymentAttributes['status'];
        paymentMethodId: PaymentMethod['paymentMethodId'];
        paymentGatewayType: PaymentMethod['paymentGatewayType'];
        paymentMethodType: PaymentMethod['paymentMethodType'];
        brand: PaymentMethod['brand'];
        last4: PaymentMethod['last4'];
        expiryMonth: PaymentMethod['expiryMonth'];
        expiryYear: PaymentMethod['expiryYear'];
        externalPaymentCaptureId: PaymentMethod['externalPaymentCaptureId'];
    };
    subscription?: {
        name: string;
        subscriptionFeeInterval: number;
        subscriptionFeePrice: number;
        orderFee: number;
        giftCardFee: number;
        subscriptionStatus: 'ACTIVE' | 'INACTIVE';
    };
    billingAddress: UserBillingAddress;
    referralCashbackAmount?: number | null;
    referralCashbackReason?: typeof ReferralCashbackEnum[number] | null;
    orderType: typeof B2BOrderTypeEnum[number] | typeof EGifterOrderTypeEnum[number];
    orderUserType: typeof OrderUserTypeEnum[number];
    orderFailureReason: OrderFailureReason | null;
    orderFailureErrorMessage?: string | null;
}

interface BounceReason {
    type: 'Undetermined' | 'Permanent' | 'Transient';
    subType:
        | 'Undetermined'
        | 'General'
        | 'NoEmail'
        | 'Suppressed'
        | 'OnAccountSuppressionList'
        | 'MailboxFull'
        | 'MessageTooLarge'
        | 'ContentRejected'
        | 'AttachmentRejected';
}

interface ComplaintReason {
    type: 'OnAccountSuppressionList' | null;
    subType: 'abuse' | 'auth-failure' | 'fraud' | 'not-spam' | 'other' | 'virus';
}

export const OrderItemRecipientDeliveryTypeEnum = ['EMAIL', 'SMS', 'CODES'] as const;

export type EmailFailureReason = {
    bounce?: BounceReason;
    complaint?: ComplaintReason;
};

export type OrderItemRecipient = {
    email: string;
    phone?: string;
    country?: string;
    status?: typeof OrderItemRecipientStatusEnum[number];
    deliveryType: typeof OrderItemRecipientDeliveryTypeEnum[number];
    message?: string;
    /**
     * MessageId generated by SES
     */
    externalMessageId?: string | null;
    /**
     * failure reason generated by SES notification
     */
    emailFailureReason?: EmailFailureReason | null;
    smsFailureReason?: string | null;
    quantity?: number;
    orderItemId?: number;
    recipientId: number;
    firstName?: string | null;
    lastName?: string | null;
    listName: string;
    deliveryScheduledAtTimestamp?: number | null;
    deliveryScheduledAtTimezone?: RecipientDeliveryScheduledAtType['timezone'] | null;
    barcodeCodes?: BarcodeCode[] | null;
    barcodePath?: string | null;
    /**
     * no of times a schedule is updated
     */
    noOfScheduleUpdates?: number | null;
    /**
     * Time the email was delivered to the recipient
     */
    deliveryAt?: string | null;
    /**
     * Time the email was opened by the recipient
     */
    openedAt?: string | null;
    /**
     * Time the claimlink was clicked by the recipient
     */
    clickedAt?: string | null;
};
