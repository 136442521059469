import React from 'react';
import { Box, Button, InputAdornment, TextField } from '@material-ui/core';
import ConfirmationDialog from '../common/confirmation-dialog';
import { formatDinero, getDinero, validateAmount } from '../../utilities';

type Props = {
    userId: number;
    inputTitle: string;
    fetchFundBalance: () => Promise<void>;
    creditFundsAPI: (amount: number) => Promise<boolean> | undefined;
};

const AddFunds = (props: Props) => {
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);
    const [isSaving, setIsSaving] = React.useState(false);
    const [amount, setAmount] = React.useState('');

    const isValidAmount = validateAmount(amount);

    const openDialog = () => {
        if (!isValidAmount || !+amount) {
            return;
        }
        setIsDialogOpen(true);
    };

    const closeDialog = () => setIsDialogOpen(false);

    const onConfirm = async () => {
        setIsSaving(true);
        const apiResponse = await props.creditFundsAPI(+amount);;
        if (!apiResponse) {
            closeDialog();
            setIsSaving(false);
            return;
        }

        await props.fetchFundBalance();
        setAmount('');
        closeDialog();
        setIsSaving(false);
    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setAmount(value);
    };

    React.useEffect(() => {
        setAmount('');
    }, [props.userId]);

    return (
        <Box display="flex" alignItems="center" gridGap={8} my={2}>
            <TextField
                label={props.inputTitle}
                InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                }}
                value={amount}
                error={!isValidAmount}
                helperText={!isValidAmount && 'Please enter a valid amount.'}
                onChange={onChange}
            />

            <div>
                <Button variant="contained" type="button" color="primary" onClick={openDialog}>
                    Add Funds
                </Button>
            </div>

            <ConfirmationDialog
                header={'Are you sure?'}
                subHeader={`Click CONFIRM to credit ${formatDinero(getDinero(+amount))}`}
                isLoading={isSaving}
                open={isDialogOpen}
                onClose={closeDialog}
                onConfirm={onConfirm}
            />
        </Box>
    );
};

export default AddFunds;
