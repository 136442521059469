import { CombinedUserType } from "../components/common/user-avatar";
import { PhotoAttributesType } from "../types/userAccount";

const createImage = (url: string): Promise<HTMLImageElement | null> =>
    new Promise((resolve) => {
        const image = new Image();
        image.addEventListener('load', () => resolve(image));
        image.addEventListener('error', (error) => resolve(null));
        image.setAttribute('crossOrigin', 'anonymous');
        image.src = url;
    });

export type BlobAndBase64UrlType = {
    blob: Blob;
    base64Url: string;
    photoAttributes: PhotoAttributesType | null;
};

// Converts the degree of rotation into radian value
const getRadianAngle = (degreeValue: number) => {
    return (degreeValue * Math.PI) / 180;
};

/**
 * Returns the new bounding area of a rotated rectangle.
 */
const rotateSize = (width: number, height: number, rotation: number) => {
    const rotRad = getRadianAngle(rotation);

    return {
        width: Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
        height: Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height)
    };
};

export const getCroppedBlobAndBase64Url = async (
    imageSrc: string,
    photoAttributes: PhotoAttributesType | undefined,
    mimetype?: string | null
): Promise<BlobAndBase64UrlType | null> => {
    const image = await createImage(imageSrc);
    if (!image) {
        return null;
    }

    const width = photoAttributes?.width || image.width;
    const height = photoAttributes?.height || image.height;

    const x = photoAttributes?.x || image.x;
    const y = photoAttributes?.y || image.y;

    const rotationDegrees = photoAttributes?.rotation || 0;

    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');
    if (!ctx) {
        return null;
    }

    ctx.clearRect(0, 0, width, height);
    ctx.fillStyle = '#ffffff';
    ctx.fillRect(0, 0, width, height);
    ctx.save();

    //Find the bounding box of the cropped image and draw it on canvas

    const rotRad = getRadianAngle(rotationDegrees);

    // calculate bounding box of the rotated image
    const { width: bBoxWidth, height: bBoxHeight } = rotateSize(image.width, image.height, rotationDegrees);

    // set canvas size to match the bounding box
    canvas.width = bBoxWidth;
    canvas.height = bBoxHeight;

    // translate canvas context to a central location to allow rotating  around the center
    ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
    ctx.rotate(rotRad);
    ctx.scale(1, 1);

    ctx.translate(-image.width / 2, -image.height / 2);

    // draw rotated image
    ctx.drawImage(image, 0, 0);

    // croppedAreaPixels values are bounding box relative - extract the cropped image using these values
    const data = ctx.getImageData(x, y, width, height);

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = width;
    canvas.height = height;

    // paste generated rotate image at the top left corner
    ctx.putImageData(data, 0, 0);

    ctx.restore();

    // As a blob
    return new Promise((resolve) => {
        canvas.toBlob((blob) => {
            if (!blob) {
                resolve(null);
                return;
            }

            resolve({
                blob,
                base64Url: canvas.toDataURL(mimetype || 'image/jpeg'),
                photoAttributes: photoAttributes || null
            });
        }, mimetype || 'image/jpeg');
    });
};

export const getBase64Url = async (user: Omit<CombinedUserType, 'privileges'>) => {
    if (!user?.photo) {
        return null;
    }

    const isPhotoFromS3Bucket = user.photo.indexOf(`${process.env.REACT_APP_AWS_S3_USER_PHOTOS_FILEPATH}/${user?.userId}/`) !== -1;
    const photo = isPhotoFromS3Bucket && `${process.env.REACT_APP_AWS_S3_USER_PHOTOS_BASE_URL}/${user.photo}` ||
        user.photo;

    return photo;
};
